import React from 'react';

import { AnimatedContainer, FooterSection, Wrapper } from './styles';

const year = new Date().getFullYear();

export const Footer: React.FC = () => {
  return (
    <AnimatedContainer>
      <FooterSection>
        <Wrapper>
          <p>DIWE Ventures</p>

          <h4>Copyright &copy; {year}</h4>
        </Wrapper>
      </FooterSection>
    </AnimatedContainer>
  );
};
