import React from 'react';
import { FieldError } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { ColumnProps } from '../Column';
import { Column, Text } from 'src/components';

interface InputProps {
  name: string;
  placeholder?: string;
  error?: FieldError;
  type?: string;
  disabled?: boolean;
}

interface TextareaComponentProps extends InputProps, ColumnProps {
  label?: string;
  onChange(): void;
  value: string;
}

const TextareaComponent: React.FC<TextareaComponentProps> = ({
  name,
  value,
  onChange,
  label,
  placeholder,
  error,
  type,
  disabled,
  ...props
}) => {
  return (
    <Column {...props}>
      {label && <Text variant="regular">{label}</Text>}
      <Input
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        type={type}
        disabled={disabled}
      />
      {error?.message && (
        <Text variant="tiny" color="red">
          {error?.message}
        </Text>
      )}
    </Column>
  );
};

const Input = styled.textarea<InputProps>`
  height: 250px;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  ${({ error }) => {
    if (error?.message) {
      return css`
        border-color: red;
      `;
    }
  }}
`;

export default TextareaComponent;
