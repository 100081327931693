import { styled } from 'src/modules';

export const AnimatedContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transform-origin: bottom;
  align-items: center;
  margin-top: auto;

  margin-top: 10px;
`;

export const FooterSection = styled(AnimatedContainer)<{ background?: string }>`
  max-width: 1040px;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  height: auto;

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  @media (max-width: 568px) {
    flex-direction: column;
    text-align: center;
  }
`;
