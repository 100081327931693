import light from './light';

const dark: typeof light = {
  ...light,
  colors: {
    ...light.colors,
    gray: '#d4d6e0',
    lightGray: '#343233',
    darkGray: '#f3f3f3',
    background: '#272727',
  },
};

export default dark;
