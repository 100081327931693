import { FC } from 'react';
import styled from 'styled-components';
import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
} from 'styled-system';

export interface RowProps
  extends SpaceProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    PositionProps,
    FlexboxProps {}

const RowComponent: FC<RowProps> = styled.div<RowProps>(
  { display: 'flex' },
  space,
  layout,
  color,
  border,
  position,
  flexbox,
);

export default RowComponent;
