export enum ApplierResponseStatus {
  PENDING = 'PENDING',
  REFUSED = 'REFUSED',
  ACCEPTED = 'ACCEPTED',
}

export enum ApplierResponseScreen {
  'PENDING' = 'Pendente',
  'REFUSED' = 'Recusada',
  'ACCEPTED' = 'Aceita',
}

export enum ApplierResponseSituation {
  'PENDING' = 'Ainda não respondeu ao convite',
  'REFUSED' = 'Não está interessado no serviço',
  'ACCEPTED' = 'Está interessado no serviço',
}
export enum ModalOptions {
  DECLINE_FIRST = 'declineFirstModal',
  DECLINE_SECOND = 'declineSecondModal',
  ACCEPTED = 'acceptModal',
}

export enum RefusalReason {
  SHORT_DEADLINE = 'Prazo muito curto',
  OFF_LOCATIONS = 'Fora da minha localização',
  OTHER_SERVICE = 'Estou realizando outro serviço',
  OTHER = 'Outro motivo',
}
