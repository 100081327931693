import { styled } from 'src/modules';

type WrapperProps = {
  marginTop?: number;
  marginBottom?: number;
};

export const Wrapper = styled.a<WrapperProps>`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.brand.primary.main};
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  width: 100%;
`;

export const Text = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  margin-right: 16px;
  font-weight: 500;
  :active {
    color: ${({ theme }) => theme.colors.white};
  }
  :link {
    color: ${({ theme }) => theme.colors.white};
  }
  :visited {
    color: ${({ theme }) => theme.colors.white};
  }
  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
  :active {
    color: ${({ theme }) => theme.colors.white};
  }
`;
