import { styled } from 'src/modules';

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
`;
export const Container = styled.div`
  position: absolute;
  transform: translateY(100%);
  width: 350px;
  height: 400px;
  z-index: 3;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  @media only screen and (max-width: 970px) {
    transform: translateY(150%);
  }
`;

export const CropContainer = styled.div`
  position: absolute;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 90px;
`;

export const Controls = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputZoomRange = styled.input`
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
`;

export const FinishButton = styled.div`
  width: 100%;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.redHighlight};
  border-radius: 5px;
  color: white;
  font-size: 15px;
  text-align: center;
`;
