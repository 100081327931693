import React from 'react';
import * as S from './styles';
import {
  BluePost,
  MarinePost,
  PurplePost,
  RedPost,
} from 'src/assets/images/posts';

interface Props {
  frame: FrameType;
}

const PostTemplate = {
  RED: RedPost,
  BLUE: BluePost,
  PURPLE: PurplePost,
  MARINE: MarinePost,
};

const textColors = {
  RED: '#FFFFFF',
  BLUE: '#FFFFFF',
  PURPLE: '#FFFFFF',
  MARINE: '#FFFFFF',
};
const Frame: React.FC<Props> = ({ frame }) => {
  const textColor = textColors[frame.backgroundColor];
  // const username = frame.name;
  return (
    <>
      <S.Frame id="moldura">
        <img
          style={{
            width: '100%',
            height: '100%',
            zIndex: 1,
            borderRadius: 8,
          }}
          src={PostTemplate[frame.backgroundColor]}
          alt=""
        />
        <S.TopContainer>
          <S.FrameTextContainer>
            <p style={{ color: textColor }}>
              A Diwe está de cara nova! E nós queremos te convidar para estar
              junto com a gente e participar de um novo momento muito especial.
            </p>
            <p style={{ color: textColor }}>#Diwe #DiweVenture</p>
            <p style={{ color: textColor }}>#DiweProfoundMarketinge</p>
          </S.FrameTextContainer>
        </S.TopContainer>
        <S.ImageWrapper>
          <S.FramePicture src={frame.picturePath} alt="Foto selecionada" />
        </S.ImageWrapper>
      </S.Frame>
    </>
  );
};
export default Frame;
