import React from 'react';
import { Row, RowProps } from '../Row';

export type ColumnProps = RowProps;

const ColumnComponent: React.FC<ColumnProps> = (props) => (
  <Row flexDirection="column" {...props} />
);

export default ColumnComponent;
