import React from 'react';

import { Row, Wrapper } from './styles';
import { CheckCircle } from 'src/assets/svg';

type ColorOption = {
  name: FrameColor;
  image: any;
};
const colorOptions: ColorOption[] = [
  { name: 'RED', image: '#E73331' },
  { name: 'BLUE', image: '#22C1FF' },
  { name: 'PURPLE', image: '#212166' },
  { name: 'MARINE', image: '#6F28CF' },
];
export type ColorPickerProps = {
  onSelectColor: (color: FrameColor) => void;
  selectedColor: FrameColor | undefined;
};
const ColorPicker: React.FC<ColorPickerProps> = ({
  onSelectColor,
  selectedColor,
}) => {
  return (
    <Wrapper>
      <span>Escolha a cor de fundo</span>
      <Row>
        {colorOptions.map((color) => (
          <div
            onClick={() => onSelectColor(color.name)}
            style={{
              width: 45,
              height: 45,
              borderRadius: 45,
              cursor: 'pointer',
              background: color.image,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            {selectedColor === color.name ? (
              <img
                style={{ width: 16, height: 16 }}
                src={CheckCircle}
                alt="ícone de seleção"
              />
            ) : null}
          </div>
        ))}
      </Row>
    </Wrapper>
  );
};

export default ColorPicker;
