import { Props } from './index';
import { styled } from 'src/modules';

export const Wrapper = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brand.primary.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  padding: 16px 0;
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;

  @media only screen and (max-width: 370px) {
    width: 100%;
  }
`;
