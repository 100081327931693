import { create } from 'mobx-persist';
import AuthStore from './auth.store';
import FrameStore from './frame.store';

class RootStore {
  auth: AuthStore;

  frameStore: FrameStore;

  constructor() {
    this.auth = new AuthStore();
    this.frameStore = new FrameStore();
  }
}

const hydrate = create({
  storage: localStorage,
});
const store = new RootStore();

hydrate('auth', store.auth);

export { AuthStore };
export default store;
