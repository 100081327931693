export enum MenuIcons {
  user = 1,
  products = 2,
  rewards = 3,
  levels = 4,
}

export enum MenuRoutes {
  user = 'USER',
  rewards = 'REWARDS',
  'earn-points' = 'REWARDS',
  levels = 'REWARDS',
}
