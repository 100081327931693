import React from 'react';
import { observer } from 'mobx-react';
import FrameScreen from './FrameScreen';
import { useStores } from 'src/hooks';
import { useHistory } from 'src/modules';

const FrameContainer: React.FC = () => {
  const history = useHistory();

  const { frameStore } = useStores();

  const goBack = () => history.goBack();

  return <FrameScreen goBack={goBack} frame={frameStore.frame} />;
};

export default observer(FrameContainer);
