import { PipelineSteps } from 'src/utils';

export const getPipelineStepBackground = (step: string): string => {
  switch (step) {
    case PipelineSteps.NONE:
      return 'rgba(242, 34, 68, 1)';
    case PipelineSteps.REQUEST:
      return 'rgba(123, 97, 255, 1)';
    case PipelineSteps.MAPPING:
      return 'rgba(255, 187, 34, 1)';
    case PipelineSteps.SENT_PROFESSIONALS:
      return 'rgba(51, 124, 227, 1)';
    case PipelineSteps.NEGOTIATION:
      return 'rgba(51, 124, 227, 1)';
    case PipelineSteps.DONE_DEAL:
      return 'rgba(51, 124, 227, 1)';
    case PipelineSteps.SERVICE_EXECUTION:
      return 'rgba(51, 124, 227, 1)';
    case PipelineSteps.CONVERTED:
      return 'rgba(51, 124, 227, 1)';
    case PipelineSteps.LOST:
      return 'rgba(51, 124, 227, 1)';

    default:
      return 'rgba(242, 34, 68, 1)';
  }
};
