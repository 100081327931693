import React, { useRef, useState } from 'react';
import {
  CTAwrapper,
  ClearButton,
  Content,
  FormBox,
  FormBoxChild,
  HashtagsText,
  Heading,
  InputWrapper,
  Lead,
  LeftContent,
  Main,
  RightContent,
  Row,
  SelectImageText,
  Top,
  TopImage,
  TopImageMobile,
  TopMobile,
} from './styles';

import { TopBanner, TopBannerMobile } from 'src/assets/images/form';
import { AddPicture, ArrowDownCircle } from 'src/assets/svg';
import { ColorPicker, Stepper } from 'src/components';
import { Button, Footer, Input } from 'src/components/common';
import CropperComponent from 'src/components/Cropper';
import { StepType } from 'src/components/Stepper/Step';
import { useStores } from 'src/hooks';
import { useHistory } from 'src/modules';
import { showAlert } from 'src/utils/helpers';

const FORM_STEPS: StepType[] = [
  {
    title: 'Escolha uma foto',
    description:
      'Clique na caixa ao lado para tirar uma foto ou escolher uma de sua galeria para ser utilizada em seu post.',
  },
  {
    title: 'Escolha uma cor de fundo',
    description: 'Você tem 4 opções de cores de fundo para usar em seu post.',
  },
  {
    title: 'Preencha seu nome',
    description: 'Coloque seu nome no campo ao lado e clique em “Criar post”.',
  },
  {
    title: 'Baixe seu post',
    description:
      'Clique no botão “Baixar post” para salvar em seu computador ou celular.',
  },
  {
    title: 'Compartilhe seu post',
    description:
      'Escolha uma rede social e compartilhe seu post com outras pessoas.',
  },
];

export const Form: React.FC = () => {
  // stores
  const { frameStore } = useStores();

  // hooks
  const history = useHistory();

  // states
  /* @TODO: Return 'name' prop */
  const [name, setName] = useState<string>('');
  const [image, setImage] = useState<string | undefined>('');
  const [croppedImage, setCroppedImage] = useState<string | undefined>('');
  const [backgroundColor, setBackgroundColor] = useState<FrameColor>();
  const [showCrop, setShowCrop] = useState(false);

  // consts
  const paddingInputWrapper = croppedImage ? 0 : 33;

  // ref
  const inputRef = useRef<HTMLInputElement>(null);
  // functions
  const onSelectImage = async (event: any) => {
    if (event && event.currentTarget.files) {
      try {
        const imagePath = URL.createObjectURL(event.currentTarget.files[0]);
        setImage(imagePath);
        setShowCrop(true);
      } catch {
        showAlert({
          message: 'Algo deu errado ao selecionar a imagem, tente novamente',
          position: 'top-right',
        });
      }
    }
  };

  const onChangeText = (name: string) => setName(name);

  const onClearImage = () => {
    setImage(undefined);
    setCroppedImage(undefined);
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }
  };

  const onCreate = () => {
    if (!croppedImage) {
      showAlert({
        message: 'Envie uma imagem, por favor',
        position: 'top-right',
      });
      return;
    }
    if (!name) {
      showAlert({
        message: 'Preencha seu primeiro nome, por favor.',
        position: 'top-right',
      });
      return;
    }
    if (!backgroundColor) {
      showAlert({
        message: 'Selecione uma cor de fundo, por favor',
        position: 'top-right',
      });
      return;
    }
    frameStore.setFrame({ name, picturePath: croppedImage, backgroundColor });
    history.push('/frame');
  };

  const onCropImage = (croppedImage: any) => {
    setShowCrop(false);
    setCroppedImage(croppedImage);
  };
  const CTA = () => (
    <CTAwrapper href="#form">
      <a href="#form">Faça seu post</a>
      <img src={ArrowDownCircle} alt="Faça seu post" />
    </CTAwrapper>
  );
  return (
    <>
      {showCrop && image ? (
        <CropperComponent image={image} onCrop={onCropImage} />
      ) : null}

      <Main>
        {/*
         */}
        <Top>
          <TopImage src={TopBanner} />
          <CTA />
        </Top>

        <TopMobile>
          <TopImageMobile src={TopBannerMobile} />
          <CTA />
        </TopMobile>
        <Row>
          <Lead>
            A Diwe está de cara nova! E nós queremos te convidar para estar
            junto com a gente e participar de um novo momento muito especial.
          </Lead>
          <HashtagsText>
            Quer saber como? É só seguir os passos abaixo usando as hashtags
            <span> #Diwe #DiweVenture #DiweProfoundMarketing </span> e pronto!
          </HashtagsText>
        </Row>
        <Content>
          <LeftContent>
            <Heading>
              Essas são as etapas necessárias para criar seu post
            </Heading>
            <Stepper steps={FORM_STEPS} />
          </LeftContent>
          <RightContent>
            <FormBox id="form">
              <FormBoxChild>
                <h4>
                  Envie sua foto para gerar seu post em comemoração à nova marca
                  Diwe.
                </h4>

                <InputWrapper
                  style={{
                    paddingBlock: paddingInputWrapper,
                    marginBottom: image ? 0 : 24,
                  }}
                  htmlFor="inputImageFile"
                >
                  <input
                    ref={inputRef}
                    accept="image/*"
                    type="file"
                    onChange={onSelectImage}
                    id="inputImageFile"
                    hidden
                  />
                  {croppedImage ? (
                    <>
                      <img
                        width="100%"
                        height="100%"
                        src={croppedImage}
                        alt="previewImage"
                      />
                    </>
                  ) : (
                    <>
                      <img src={AddPicture} alt="Adicionar foto" />
                      <SelectImageText>
                        Clique para selecionar uma imagem da galeria ou tirar
                        uma nova foto
                      </SelectImageText>
                    </>
                  )}
                </InputWrapper>
                {croppedImage ? (
                  <ClearButton onClick={onClearImage} type="button">
                    Remover
                  </ClearButton>
                ) : null}
                <ColorPicker
                  onSelectColor={setBackgroundColor}
                  selectedColor={backgroundColor}
                />
                <Input
                  maxLength={25}
                  label="Nome"
                  placeholder="Informe seu nome"
                  onChangeText={onChangeText}
                />
                {/* @TODO: Disabled if no picture selected */}
                <Button onClick={onCreate}>
                  <span>Criar post</span>
                </Button>
              </FormBoxChild>
            </FormBox>
          </RightContent>
        </Content>
        <Footer />
      </Main>
    </>
  );
};

export default Form;
