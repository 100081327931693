import { styled } from 'src/modules';

const boldBlack = '#1E1E1C';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 14px;
  span {
    font-weight: 700;
    font-size: 16px;
    color: ${boldBlack};
    margin-bottom: 4px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
`;
