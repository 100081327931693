export const userInitialValues: User = {
  id: 0,
  full_name: '',
  phone: '',
  email: '',
  status: '',
  role_id: 0,
  created_at: '',
  updated_at: '',
};
