export enum PipelineSteps {
  NONE = 'NONE',
  REQUEST = 'REQUEST',
  MAPPING = 'MAPPING',
  SENT_PROFESSIONALS = 'SENT_PROFESSIONALS',
  NEGOTIATION = 'NEGOTIATION',
  DONE_DEAL = 'DONE_DEAL',
  SERVICE_EXECUTION = 'SERVICE_EXECUTION',
  CONVERTED = 'CONVERTED',
  LOST = 'LOST',
}
