/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import domtoimage from 'dom-to-image';
import { FiArrowLeft, FiDownload } from 'react-icons/fi';
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Frame, Stepper } from '../../components';
import {
  Content,
  Description,
  FrameChild,
  FrameContainer,
  GoBack,
  Heading,
  Left,
  LeftContainer,
  Main,
  Right,
  ShareBox,
  ShareBoxWrapper,
  ShareText,
} from './styles';
import { FacebookRed, LinkedinRed, WhatsappRed } from 'src/assets/svg';
import { Loader } from 'src/components';
import { Button, Footer } from 'src/components/common';
import { useHistory } from 'src/modules';
import { showAlert } from 'src/utils/helpers';

interface Props {
  frame: FrameType;
  goBack?(): void;
}

const socialMediaShareText =
  'Eu faço parte dos 85 anos de história da Brasilit e quartzolit! Veja meu crachá virtual:';

const FrameScreen: React.FC<Props> = ({ frame, goBack }) => {
  const {
    REACT_APP_CLOUDINARY_ENVIRONMENT,
    REACT_APP_CLOUDINARY_UPLOAD_PRESET,
    REACT_APP_CLOUDINARY_FOLDER,
  } = process.env;

  const history = useHistory();

  const [image, setImage] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [loading, setLoading] = useState(true);

  const onDownloadImage = async () => {
    const link = document.createElement('a');
    link.download = `${frame.name}.png`;
    link.href = image;
    link.click();
  };

  const sendToCloudNary = async () => {
    setLoading(true);
    const node = document.getElementById('moldura');

    if (
      node &&
      REACT_APP_CLOUDINARY_UPLOAD_PRESET &&
      REACT_APP_CLOUDINARY_FOLDER
    ) {
      domtoimage
        .toPng(node)
        .then(() => {
          domtoimage.toPng(node).then((dataUrl) => {
            setImage(dataUrl);
            const formData = new FormData();
            const url = `https://api.cloudinary.com/v1_1/${REACT_APP_CLOUDINARY_ENVIRONMENT}/image/upload`;

            formData.append('file', dataUrl);
            formData.append(
              'upload_preset',
              REACT_APP_CLOUDINARY_UPLOAD_PRESET,
            );
            formData.append('folder', REACT_APP_CLOUDINARY_FOLDER);

            fetch(url, {
              method: 'POST',
              body: formData,
            })
              .then((response) => {
                return response.text();
              })
              .then((data) => {
                const objImages = JSON.parse(data);
                setImageURL(objImages.secure_url);
              });
            setLoading(false);
          });
        })
        .catch(() => {
          showAlert({ message: 'Algo deu errado ao gerar sua imagem' });
        });
    }
  };

  useEffect(() => {
    if (!frame.picturePath) {
      history.replace('/');
    }
  }, [frame.picturePath, history]);

  useEffect(() => {
    sendToCloudNary();
  }, []);

  return (
    <Main>
      <Content>
        <Left>
          <LeftContainer>
            <GoBack onClick={goBack}>
              <FiArrowLeft size={15} />
              Voltar
            </GoBack>
            <Heading>
              Seu post está pronto para ser
              <br /> baixado ou compartilhado! ✅
            </Heading>
            <Stepper
              steps={[
                {
                  title: 'Baixe seu post',
                  description:
                    'Clique no botão “Baixar post” para salvar em seu computador ou celular.',
                },
                {
                  title: 'Compartilhe seu post',
                  description:
                    'Escolha uma rede social e compartilhe seu post com outras pessoas.',
                },
              ]}
            />
          </LeftContainer>
        </Left>
        <Right>
          <FrameContainer>
            <FrameChild>
              <Frame frame={frame} />
              <Description>
                Você já pode baixar ou compartilhar seu
                <br />
                post nas redes sociais! 😉
              </Description>
              <ShareText>Compartilhe nas suas redes sociais</ShareText>
              {loading && imageURL ? (
                <Loader />
              ) : (
                <>
                  <ShareBoxWrapper>
                    <WhatsappShareButton
                      url={imageURL}
                      title={socialMediaShareText}
                    >
                      <ShareBox>
                        <img src={WhatsappRed} alt="Logo whatsapp" />
                      </ShareBox>
                    </WhatsappShareButton>
                    <FacebookShareButton
                      url={imageURL}
                      quote={socialMediaShareText}
                      hashtag={socialMediaShareText}
                    >
                      <ShareBox>
                        <img src={FacebookRed} alt="Logo facebook" />
                      </ShareBox>
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={imageURL}
                      title={socialMediaShareText}
                      summary={socialMediaShareText}
                    >
                      <ShareBox>
                        <img src={LinkedinRed} alt="Logo linkedin" />
                      </ShareBox>
                    </LinkedinShareButton>
                  </ShareBoxWrapper>
                  {imageURL && (
                    <Button onClick={onDownloadImage}>
                      <FiDownload size={18} color="#FFFFFF" />
                      <span>Baixar post</span>
                    </Button>
                  )}
                </>
              )}
            </FrameChild>
          </FrameContainer>
        </Right>
      </Content>
      <Footer />
    </Main>
  );
};

export default FrameScreen;
