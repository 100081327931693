import React, { ButtonHTMLAttributes } from 'react';

import { Wrapper } from './styles';

export type Props = {
  marginTop?: number;
  marginBottom?: number;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<Props> = ({
  marginTop = 40,
  marginBottom = 0,
  children,
  ...props
}) => {
  return (
    <Wrapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      type="button"
      {...props}
    >
      {children}
    </Wrapper>
  );
};
