import React from 'react';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import Routes from './routes';
import store from './stores';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyles from './styles/global';
import { light } from './styles/themes';
import { Toastify } from 'src/modules';
import 'antd/dist/antd.css';

const routingStore = new RouterStore();
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routingStore);

if (process.env.REACT_APP_ENV !== 'DEV') {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_CONTAINER || '',
  };

  TagManager.initialize(tagManagerArgs);
}

const stores = {
  ...store,
  routing: routingStore,
};

render(
  <Provider {...stores}>
    <ThemeProvider theme={light}>
      <Router history={history}>
        <Toastify.ToastContainer />
        <StylesProvider injectFirst>
          <Routes />
        </StylesProvider>

        <GlobalStyles />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
