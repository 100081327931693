export enum BudgetPipeSteps {
  NONE = 'Nenhum',
  REQUEST = 'Solicitação',
  MAPPING = 'Mapeamento',
  SENT_PROFESSIONALS = 'Apresentação Profissionais',
  NEGOTIATION = 'Negociação',
  DONE_DEAL = 'Negócio Fechado',
  SERVICE_EXECUTION = 'Execução do Serviço',
  CONVERTED = 'Ganho',
  LOST = 'Perdido',
}
