export enum UserRoles {
  admin = 'admin',
  moderator = 'moderator',
  user = 'user',
}

export enum UserRoleExhibition {
  admin = 'Administrador',
  moderator = 'Moderador',
  user = 'Usuário',
}

export enum UserGenders {
  male = 'male',
  female = 'female',
  other = 'other',
}

export enum UserGenderExhibition {
  male = 'Masculino',
  female = 'Feminino',
  other = 'Não informado',
}
