import request from '../request';
import { ResponseError } from 'src/utils';

export const login = async (credentials: Credentials): Promise<Token> => {
  try {
    const { data } = await request.post('/auth/login', credentials);
    return data.token;
  } catch (error) {
    throw new ResponseError(error);
  }
};
