export enum Routes {
  HOME = '/appliers',
  APPLIER_PROFILE = '/applier/profile',
  APPLIER_BUDGET = '/applier-budget/details',
  APPLIER_INVITATION = '/applier-budget/invitation',
  LOGIN = '/login',
  BUDGETS = '/budgets',
  BUDGETS_DETAILS = '/budgets/details',
  BUDGETS_UPDATE_STATUS = '/budgets/update-status',
  BUDGETS_APPLIERS = '/budgets/appliers',
  BUDGET_REQUEST = '/budgets/request',
  FORM = '/',
  FRAME = '/frame',
}
