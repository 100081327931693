import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch } from 'react-router-dom';
import Public from './public';
import { Routes } from './routing';
import { FormScene, FrameScene } from 'src/screens';

const RoutesContainer: React.FC = () => (
  <Switch>
    <Public exact path="/" component={FormScene} />
    <Public exact path={`${Routes.FRAME}`} component={FrameScene} />
  </Switch>
);

export { Routes };
export default inject('routing')(observer(RoutesContainer));
