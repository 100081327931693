import { styled } from 'src/modules';

export const Main = styled.main`
  width: 100%;
  margin: auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CTAwrapper = styled.a`
  position: absolute;
  top: 74%;
  left: 21%;
  display: flex;
  gap: 10px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.brand.primary.light};
  width: 22%;
  color: white;

  a {
    font-size: 16px;
    font-weight: 500;
    :active {
      color: ${({ theme }) => theme.colors.white};
    }
    :link {
      color: ${({ theme }) => theme.colors.white};
    }
    :visited {
      color: ${({ theme }) => theme.colors.white};
    }
    :hover {
      color: ${({ theme }) => theme.colors.white};
    }
    :active {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media screen and (max-width: 889px) {
    width: 70%;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 24px;
    padding: 16px 24px;
  }
`;
export const TopImage = styled.img`
  width: 100%;
  height: auto;
`;

export const TopImageMobile = styled.img`
  width: 100%;
  height: auto;
`;
export const Top = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;
export const TopMobile = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  background-color: green;
  @media screen and (min-width: 499px) {
    display: none;
  }
`;

export const TopHeading = styled.h1`
  font-size: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.text.dark};
  span {
    color: ${({ theme }) => theme.colors.redHighlight};
  }
  @media only screen and (max-width: 760px) {
    font-size: 31px;
  }
`;

export const TopParagraph = styled.p`
  margin-top: 24px;
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 500;
  @media only screen and (max-width: 760px) {
    font-size: 16px;
  }
`;

export const SecondaryText = styled.p`
  margin-top: 56px;
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 500;
  span {
    font-weight: bold;
    font-style: italic;
  }
`;

export const PromotrionalBanner = styled.img`
  width: 90%;
  height: auto;
  margin-top: 54px;
  margin-bottom: -120px;
  border-radius: 8px;
  filter: drop-shadow(0px -9px 45px rgba(109, 21, 23, 0.26));
  @media only screen and (max-width: 510px) {
    width: 100%;
  }
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-top: 65px;
  max-width: 1040px;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 24px;
  flex-direction: row;

  @media only screen and (max-width: 970px) {
    padding: 48px 24px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Row = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-top: 65px;
  width: 606px;
  @media screen and (max-width: 625px) {
    width: 100%;
    padding-inline: 10px;
  }
`;

export const Lead = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
  span {
    font-weight: bold;
  }
`;

export const HashtagsText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: center;
  span {
    font-weight: bold;
    font-style: italic;
  }
`;
export const LeftContent = styled.div`
  width: 504px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 970px) {
    width: 404px;
  }

  @media only screen and (max-width: 420px) {
    width: 358px;
  }

  @media only screen and (max-width: 370px) {
    width: 320px;
  }
`;
export const FormBoxChild = styled.div`
  display: flex;
  flex-direction: column;
  transform: skewY(10deg);
`;
export const FormBox = styled.div`
  border-radius: 20px;
  display: flex;
  padding: 48px 24px;
  background-color: ${({ theme }) => theme.colors.brand.background.z5};
  flex-direction: column;
  align-items: center;
  transform: skew(0deg, -10deg);
  width: 404px;
  h4 {
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-top: 32px;
  }

  img:first-child {
    width: 138px;
  }

  @media only screen and (max-width: 970px) {
    margin-top: 40px;

    img:first-child {
      width: 121px;
    }
  }

  @media only screen and (max-width: 420px) {
    width: 358px;

    img:first-child {
      width: 108px;
    }
  }

  @media only screen and (max-width: 370px) {
    width: 320px;
  }

  @media only screen and (max-width: 370px) {
    width: 100%;
  }
`;
export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h3`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const QRCodeImage = styled.img`
  margin-top: 40px;
  width: 400px;
  @media only screen and (max-width: 970px) {
    width: 100%;
  }
`;

export const LogoBox = styled.div`
  margin-top: 24px;
  border-radius: 17px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.brand.background.z0};

  @media only screen and (max-width: 970px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 300px) {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.label`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px dashed ${({ theme }) => theme.colors.textLight};
  cursor: pointer;
`;

export const ClearButton = styled.button`
  font-size: 15px;
  margin-top: 5px;
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.redHighlight};
  background-color: transparent;
`;
export const SelectImageText = styled.span`
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.brand.text.light};
  text-align: center;
  font-size: 16px;
`;

export const Button = styled.button`
  margin-top: 40px;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.redHighlight};
`;
