import React from 'react';
import { FieldError } from 'react-hook-form';
import styled, { css } from 'styled-components';

type InputComponentProps = {
  label?: string;
  onChangeText(text: string): void;
  placeholder?: string;
  error?: FieldError;
  type?: string;
  disabled?: boolean;
  maxLength?: number;
};

const InputComponent: React.FC<InputComponentProps> = ({
  label,
  placeholder,
  error,
  type,
  disabled,
  onChangeText,
  maxLength,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      {label && <span>{label}</span>}
      <Input
        maxLength={maxLength}
        placeholder={placeholder}
        error={error}
        type={type}
        disabled={disabled}
        onChange={(e) => onChangeText(e.target.value)}
        onChangeText={onChangeText}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.colors.boldBlack};
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 4px;
  }
`;

const Input = styled.input<InputComponentProps>`
  width: 100%;
  min-height: 56px;
  border-radius: 0.5rem;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};

  ${({ error }) => {
    if (error?.message) {
      return css`
        border-color: ${({ theme }) => theme.colors.error};
      `;
    }
  }}

  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 16px;
  font-weight: 500;

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  ::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
    opacity: 0.64;
    font-weight: 500;
    font-size: 16px;
  }

  /* Internet Explorer 10-11 */
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
    opacity: 0.64;
    font-weight: 500;
    font-size: 16px;
  }

  /* Microsoft Edge */
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
    opacity: 0.64;
    font-weight: 500;
    font-size: 16px;
  }
`;

export default InputComponent;
