import { styled } from 'src/modules';

export const Frame = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 358px;
  height: 358px;
  border-radius: 8;
  background-color: 'white';
  position: 'relative';
`;

export const ImageWrapper = styled.div`
  width: 56%;
  height: auto;
  position: absolute;
  z-index: 0;
  bottom: 50px;
`;

export const PictureFrame = styled.div``;

export const FramePicture = styled.img`
  width: 100%;
  height: auto;
  border-bottom-left-radius: 8px;
`;

export const TopContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 120px;
  top: 28%;
  right: 13%;
`;

export const FrameTextContainer = styled.div`
  width: 100%;
  word-wrap: break-word;
  line-height: 13px;
  margin-left: 5px;

  p {
    text-align: justify;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    span {
      font-weight: bold;
    }
  }
`;
