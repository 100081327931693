import { styled } from 'src/modules';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 390px;
  margin-top: 24px;
`;

export const Left = styled.div`
  padding-inline: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px rgba(173, 181, 189, 0.49);
`;
export const Line = styled.div`
  height: 100%;
  width: 2px;
  border: 1px dashed ${({ theme }) => theme.colors.brand.text.light};
  position: relative;
`;
export const NumberStep = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.brand.primary.main};
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.strong`
  color: ${({ theme }) => theme.colors.brand.text.dark};
  font-size: 18px;
  font-weight: 600;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  font-weight: 500;
`;
