import React from 'react';
import Animation from 'react-lottie';
import loading from 'src/assets/animations/loader.json';

export enum Sizes {
  small = 30,
  medium = 45,
  large = 90,
}

type SizeOptions = 'small' | 'medium' | 'large';

export type Props = {
  size?: SizeOptions;
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loader: React.FC<Props> = ({ size = 'large' }): JSX.Element => (
  <Animation
    options={defaultOptions}
    isStopped={false}
    isPaused={false}
    height={Sizes[size]}
    width={Sizes[size]}
  />
);

export default Loader;
