/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button } from '../common';
import getCroppedImg from './cropImage';
import * as S from './styles';

type Props = {
  image: string;
  onCrop(croppedImage: any): void;
};
const CropperComponent: React.FC<Props> = ({ image, onCrop }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState<any>(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const onFinishCrop = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
      );
      setCroppedImage(croppedImage);
      onCrop(croppedImage);
    } catch (e) {
      // @TODO(Glauber): Throws a error
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.CropContainer>
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 5}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </S.CropContainer>
        <S.Controls>
          <S.InputZoomRange
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(Number(e.target.value));
            }}
            className="zoom-range"
          />

          <Button marginTop={12} onClick={onFinishCrop}>
            Recortar foto
          </Button>
        </S.Controls>
      </S.Container>
    </S.Wrapper>
  );
};

export default CropperComponent;
