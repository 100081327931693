import React from 'react';
import * as S from './styles';

export type StepType = {
  step?: number;
  title: string;
  description: string;
  line?: boolean;
};
const Step: React.FC<StepType> = ({ step, title, description, line }) => {
  return (
    <S.Container>
      <S.Left>
        <S.Circle>
          <S.NumberStep>{step}</S.NumberStep>
        </S.Circle>
        {line ? <S.Line /> : null}
      </S.Left>
      <S.Right>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.Right>
    </S.Container>
  );
};

export default Step;
