import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export default class FrameStore {
  @persist('object')
  @observable
  frame: FrameType = {
    name: '',
    picturePath: '',
    backgroundColor: 'RED',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  setFrame = async (frame: FrameType): Promise<FrameType> => {
    this.frame = frame;
    return frame;
  };
}
