import React from 'react';
import Step, { StepType } from './Step';
import * as S from './styles';

export type StepperType = {
  steps: StepType[];
};
const Stepper: React.FC<StepperType> = ({ steps }) => {
  return (
    <S.StepsContainer>
      {steps.map(({ title, description }, index) => {
        const renderLine = index < steps.length - 1;
        return (
          <Step
            title={title}
            description={description}
            step={index + 1}
            line={renderLine}
          />
        );
      })}
    </S.StepsContainer>
  );
};

export default Stepper;
