import { styled } from 'src/modules';

export const Main = styled.main`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  max-width: 1040px;
  width: 100%;
  justify-content: space-between;
  padding: 46px 24px;
  flex-direction: row;

  @media only screen and (max-width: 970px) {
    padding: 48px 24px;
    flex-direction: column;
    justify-content: center;
  }
`;
export const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
`;
export const QRCodeImage = styled.img`
  margin-top: 40px;
  width: 400px;
  @media only screen and (max-width: 970px) {
    width: 100%;
  }
`;
export const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: auto;
  align-items: center;
  justify-content: center;
`;

export const GoBack = styled.a`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  svg {
    margin-right: 14px;
  }
`;

export const CelebrationImage = styled.img`
  width: 401;
  height: 146;
  margin-top: 100px;
  margin-bottom: 60px;
  @media only screen and (max-width: 970px) {
    display: none;
  }
`;
export const Heading = styled.h3`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  text-align: center;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;
export const FrameContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brand.background.z5};
  border-radius: 20px;
  padding: 40px;
  transform: skewY(-10deg);
  @media only screen and (max-width: 970px) {
    padding: 40px 5px 40px 5px;
  }
`;
export const FrameChild = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: skewY(10deg);

  button {
    svg {
      margin-right: 12px;
    }
  }
`;
export const Description = styled.span`
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const ShareText = styled.span`
  margin: 24px 0 16px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  line-height: 144%;
  font-weight: 700;
`;

export const ShareBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 24px;
`;

export const ShareBox = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.brand.background.z1};
  border-radius: 8px;
`;
